import React from 'react'
import PropTypes from 'prop-types'
import Helmet from "react-helmet";
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const AboutPageTemplate = ({ title, image, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <section className="sub">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1 margin-bottom">
            <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url(${
                  !!image.childImageSharp
                    ? image.childImageSharp.fluid.src
                    : image
                })`
              }}
            >
              <h2
                className="has-text-weight-bold is-size-1"
                style={{
                  color: "white",
                  padding: "1rem"
                }}
              >
                {title}
              </h2>
            </div>
            <PageContent className="content" content={content} />
          </div>
        </div>
      </div>
    </section>
  );
}

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.AboutPageData.edges[0]

  return (
    <Layout locale={locale}>
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta
          name="description"
          content={`${data.frontmatter.description}`}
        />
      </Helmet>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        image={data.frontmatter.image}
        title={data.frontmatter.title}
        content={data.html}
      />
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}
export default AboutPage

export const aboutPageQuery = graphql`
query aboutPageQuery($locale: String) {
  AboutPageData: allMarkdownRemark(
    filter: {frontmatter: { templateKey: {eq: "about-page"}, locale: { eq: $locale }}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        html
        frontmatter {
          title
          locale
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`;
